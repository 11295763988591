
  import { defineComponent } from "vue"
  import { Suggestion } from "@/models/suggestions"
  import { shortDate } from "@/utils/helper"

  interface Props { suggestion: Suggestion }

  export default defineComponent({
    name: 'SuggestionKeyData',
    props: {
      suggestion: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {
      return {
        shortDate,
      }
    }
  })
