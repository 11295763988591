
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Ticket } from "@/models/tickets";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"

  interface Props {
    ticketIds: string[]
  }

  export default defineComponent({
    props: {
      ticketIds: {
        type: Array,
        default: () => []
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.tickets.pagination.totalItems)

      const ticketIdsString = computed(() => props.ticketIds.join())

      const getItems = async(ctx: BvTableCtxObject) => {
        if (props.ticketIds.length === 0) return
        const ticketQuery = {
          'filter[archived]': false,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
        }

        ticketQuery['filter[id]'] = ticketIdsString.value

        await root.$store.dispatch('tickets/load', ticketQuery)
        return root.$store.state.tickets.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.subject",
          title: "Betreff",
          sortable: true,
          options: {
            hrefFunction: (data: any) => `/${data.item.type}/${data.item.id}`,
            contentFunction: (data: any) => (data.item.attributes.subject || 'Kein Betreff')
          }
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "ticketableType",
          title: "Typ",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              return data.item.attributes.ticketableType
            },
            hrefFunction: (data: any) => {
              const poly = data.item.relationships.ticketable.data
              return `/${poly.type}/${poly.id}`
            },
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.category",
          title: "Kategorie",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/IconCell.vue"),
          key: "attributes.archived",
          title: "Archiviert",
          sortable: true,
          options: {
            mappedIcons: [
              {
                value: false,
                stack: [
                  {
                    icon: 'times',
                    class: 'text-muted'
                  }
                ]
              },
              {
                value: true,
                stack: [
                  {
                    icon: 'check',
                    class: 'text-muted'
                  }
                ]
              },
            ]
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "messageCount",
          title: "Nachrichten",
          sortable: true,
          options: {
            contentFunction: (data: any) => data.item.attributes.messages.length
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "lastMessage",
          title: "Letzte Nachricht",
          sortable: true,
          options: {
            type: 'date',
            contentFunction: (data: any) => {
              const msgs = data.item.attributes.messages.sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                  return Date.parse(b.createdAt) - Date.parse(a.createdAt)
                } else { return 0 }
              })
              return msgs[0]?.createdAt
            }
          }
        },
      ])

      return {
        getItems,
        totalItems,
        columns,
        ticketIdsString
      }
    }
  })
