
  import { defineComponent } from "vue"
  import { CareGiverInstance } from "@/models/care_giver_instances"
  import { fullName, resourceUrl } from "@/utils/dataExtractors"
  import { shortDate } from "@/utils/helper"

  interface Props { cGI: CareGiverInstance }

  export default defineComponent({
    name: 'CareGiverInstanceKeyData',
    props: {
      cGI: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {

      return {
        shortDate,
        fullName,
        resourceUrl
      }
    }
  })
