
  import { defineComponent, computed } from "vue"
  import { Segment } from "@/models/segments"
  import { shortDate } from "@/utils/helper"
  import { getKeycloak } from "@/plugins/keycloak"
  import { ResourceObject } from '@/models/jsonapi'

  interface Props { segment: ResourceObject<string, Segment> }

  export default defineComponent({
    name: 'SegmentKeyData',
    props: {
      segment: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {
      const keycloakRef = getKeycloak()
      const role = keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0]
      const provPrice = computed(() => {
        const s = props.segment.attributes
        if (s) { return s.price + s.provPfs + s.provSeller } else {return 0}
      })
      const saveVal = (val: number|null) => val === null ? 'NA' : val
      return {
        shortDate,
        role,
        provPrice,
        saveVal
      }
    }
  })
